<template>
    <div class="user-index">
        <div class="main-h1">Просмотр обращения</div>
        <div class="card">
            <div class="card-body">
                <div class="card-body-title">Название</div>
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">
                            <div class="t">Произвольное название *</div>
                            <input v-model="appeal.title"/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Участники</div>
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Истец</div>
                            <select v-model="appeal.user_id">
                                <option v-for="option in users.claimants" :value="option.id" :selected="option.id == appeal.user_id">{{option.name}}</option>
                            </select>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Стадия</div>
                <div class="row">
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Стадия</div>
                            <select v-model="appeal.stage_id">
                                <option v-for="option in stages" :value="option.id" :selected="option.id == appeal.stage_id">{{option.title}}</option>
                            </select>
                        </label>
                    </div>
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Дедлайн стадии</div>
                            <input v-model="appeal.stage_dl" type="date"/>
                        </label>
                    </div>
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Ответственный</div>
                            <select v-model="appeal.implementer_id">
                                <option v-for="option in users.implementers" :value="option.id" :selected="option.id == appeal.implementer_id">{{option.name}}</option>
                            </select>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Даты</div>
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">
                            <div class="t">Обращение принято</div>
                            <input v-model="appeal.receipt_date" type="date" disabled/>
                        </label>
                    </div>
                    <div class="col-6">
                        <label class="form-label">
                            <div class="t">Дан ответ</div>
                            <input v-model="appeal.decision_date" type="date" :min="appeal.consideration_date"/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Документы</div>
                <file-list :list="files.filter(item => item.type_id == 1)" @updated="updateFiles"/>
                <upload-file doc_type="1" parent_type="appeal" :parent_id="appeal.id" @uploaded="updateFiles"/>
            </div>
            <div class="card-body">
                <div class="card-body-title">Ответы</div>
                <file-list :list="files.filter(item => item.type_id == 4)" @updated="updateFiles"/>
                <upload-file doc_type="4" parent_type="appeal" :parent_id="appeal.id" @uploaded="updateFiles"/>
            </div>
            <div class="card-body" v-if="appeal.isRequest == 1 && appeal.approve_date == null && appeal.stage_id != 7">
                <div class="card-body-title">Запрос на ознакомление</div>
                <div class="row">
                    <div class="col-4">
                        <div class="form-label">
                            <div class="t">Срок ознакомления (включая время)</div>
                            <input type="date" v-model="appeal.approve_to">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-label">
                            <div class="t">&nbsp;</div>
                            <button class="btn blue inb" @click="approve" :disabled="!appeal.approve_to">Одобрить</button>
                        </div>
                    </div>
                    <div class="col-4"></div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Связанный иск</div>
                <claim-list v-if="connected.length" :list="connected"/>
                <div class="row">
                    <div class="col-3"><input v-model="appeal.claim_id" type="number" placeholder="id дела"></div>
                </div>
            </div>
            <div class="savebtn btn blue inb mb-20" @click="saveAppeal">Сохранить</div>
            <div class="card-body" v-if="$store.state.isAdmin() || $store.state.isImplementor() || $store.state.isArbiter()">
                <div class="card-body-title">История</div>
                <button class="btn blue" @click="logGet" v-if="!log.length">Получить</button>
                <table class="table-log" v-if="log.length">
                    <thead>
                        <tr>
                            <th>Дата</th>
                            <th>Автор</th>
                            <th>Изменение</th>
                        </tr>
                    </thead>
                    <tr v-for="item in log">
                        <td>{{item.date}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.text}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import uploadFile from "../../../components/file/upload";
import fileList from "../../../components/file/list";
import claimList from '../../../components/claim/list.vue';
export default {
    name: "edit",
    components:{uploadFile,fileList,claimList},
    data() {
        return {
            appeal:{},
            users:{
                claimants:[],
                implementers:[],
            },
            stages:[],
            log:[],
            files:[],
            connected:[],
        }
    },
    methods: {
        approve() {
            this.$api.post(`appeal/${this.$route.params.id}/approve`,this.appeal).then(res => {
                if (res.data.success) {
                    this.$store.commit('showNotify',{title:'Сохранено',content:'Доступ к документам предоставлен.',type:'success'});
                    this.getAppeal();
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        updateFiles() {
            this.getFiles();
            //this.logGet();
        },
        getClaimConnected(claimId) {
            this.$api.get(`claim/${claimId}`).then(res => {
                if (res.data.success) {
                    this.connected.push(res.data.data)
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getAppeal() {
            this.$api.get(`appeal/${this.$route.params.id}`).then(res => {
                if (res.data.success) {
                    this.appeal = res.data.data;
                    //this.logGet();
                    if (res.data.data.claim_id > 0) {
                        this.getClaimConnected(res.data.data.claim_id)
                    }
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        saveAppeal() {
            this.$api.post(`appeal/${this.$route.params.id}`,this.appeal).then(res => {
                if (res.data.success) {
                    this.appeal = res.data.data;
                    this.$store.commit('showNotify',{title:'Сохранено',content:'Данные успешно сохранены.',type:'success'});
                    //this.logGet();
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        logGet() {
            this.$api.get(`appeal/${this.$route.params.id}/log`).then(res => {
                if (res.data.success) {
                    this.log = res.data.data
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getImplementers() {
            this.$api.get(`user/implementers`).then(res => {
                if (res.data.success) {
                    this.users.implementers = res.data.data
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getClaimants() {
            this.$api.get(`user/claimants`).then(res => {
                if (res.data.success) {
                    this.users.claimants = res.data.data
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getStages() {
            this.$api.get(`claim/stages`).then(res => {
                if (res.data.success) {
                    this.stages = res.data.data
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getFiles() {
            this.$api.get(`appeal/${this.$route.params.id}/file`).then(res => {
                if (res.data.success) {
                    this.files = res.data.data
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
    },
    created() {
        this.getAppeal();
        this.getImplementers();
        this.getClaimants();
        this.getStages();
        this.getFiles();
    },
    watch: {
        '$route.params.id': function () {
            if (this.$route.params.id) {
                this.connected = [];
                this.getAppeal();
                this.getImplementers();
                this.getClaimants();
                this.getStages();
                this.getFiles();
            }
        },
        appeal: {
            handler(newVal, oldVal) {
                if (oldVal.id) {
                    let btn = document.querySelector('.savebtn');
                    btn.classList.add('fixed');
                    setTimeout(() => {
                        btn.classList.add('show');
                    },100)
                }
            },
            deep: true,
        }
    }

}
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";
.savebtn {
    &.fixed {
        position: fixed;bottom:20px;left: -295px;transition: 0.2s;box-shadow: 0 0 5px $cl-blue;
        &.show {left: 295px;}
    }
}
</style>